import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';
import { useUser } from '../hooks';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {useAssets} from "../hooks/AssetsProvider";

type PrivateRouteProps = {
    component: React.ComponentType;
    roles: string[];
    requiredPermission?: string; // The specific project key to check (e.g., "bug")
};

const PrivateRoute = ({
    component: RouteComponent,
    roles,
    requiredPermission,
    ...rest
}: PrivateRouteProps) => {
    const location = useLocation();
    const [loggedInUser] = useUser();
    const { auth, setAuth } = useAuth();
    const {refetch} = useAssets();
    const navigate = useNavigate();
    const api = new APICore();

    const homePage =[
        '/',
        '/',
        '/',
        '/tickets/bug',
        '/tickets/bug',
        '/tickets/soc',
        '/tickets/soc',
        '/tickets/soc',
    ];
    const [isLoading, setIsLoading] = useState(true);
    const [redirectPath, setRedirectPath] = useState<string | null>(null);

    useEffect(() => {
        if (api.isUserAuthenticated() === false) {
            navigate('/login');
        } else if (!auth.email) {
            axios
                .get('/home/', { withCredentials: true })
                .then((response) => {
                    const {
                        main_role,
                        first_name,
                        last_name,
                        email,
                        projects,
                        users,
                        last_login,
                        username,
                        notifications,
                    } = response.data;
                    setAuth({
                        username,
                        last_login,
                        first_name,
                        last_name,
                        email,
                        main_role,
                        current_role: main_role,
                        projects,
                        users,
                        notifications,
                    });

                }).then(()=>refetch())
                .catch((err) => {
                    if (err === 'Invalid credentials') {
                        localStorage.removeItem('LoggedIn');
                        navigate('/login');
                    } else if (err === 'change password') {
                        navigate('/ChangePassword');
                    }
                })
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, [auth.email, setAuth]);

    useEffect(() => {
        const userRoles = auth.current_role?.split(',').map(Number) || [];
        const userProjects = auth.projects || [];
        const isAuthorizedByRole = roles.some((role) => userRoles.includes(Number(role)));
        const hasProjectPermission = requiredPermission
            ? userProjects.some((project: any) => project[requiredPermission] === true)
            : true;

        if (!isAuthorizedByRole || !hasProjectPermission) {
            const fallbackRole = Math.min(...userRoles);
            setRedirectPath(homePage[fallbackRole]);
        }
    }, [ roles, requiredPermission]);

    useEffect(() => {
        if (redirectPath) {
            window.location.href = redirectPath;
            // window.r(redirectPath);
        }
    }, [redirectPath]);

    if (isLoading) return null;

    if (redirectPath) {
        return null; // Prevent rendering the component while redirecting
    }

    return <RouteComponent />;
};

export default PrivateRoute;
